import React from "react";
import Typed from "react-typed";
import MainLayout from "../layout/MainLayout";
import { JobItem } from "../components/JobItem";
import { graphql } from "gatsby";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { JobList } from "../components/JobList";
import SEO from "../components/SEO";

export default ({
  pageContext: {
    company: {
      id,
      title,
      image,
      fields: { jobs },
    },
  },
}) => {
  jobs = jobs.map((job: Job) => {
    job.frontmatter.company = { id, title, image };
    return job;
  });
  return (
    <MainLayout>
      <SEO
        title={`Find Salesforce jobs at ${title}`}
        // pathname={location.pathname}
        // desc={data.description}
        // node={prismicPost}
        // article
      />
      <div
        className="jumbotron jumbotron-fluid"
        style={{
          background: "#F7F9FC",
          borderBottom: "1px solid #DEE0F2",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1 className="display-4">
                Salesforce Jobs at
                <br />
                <strong>{title}</strong>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <JobList jobs={jobs} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
